import $ from 'jquery';
// import 'core-js/shim';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
window.React = React;

$.extend({
  deleteJSON: function (url, data, callback) {
    return $.ajax({
      type: 'DELETE',
      url: url,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
      processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-HTTP-Method-Override', 'DELETE');
      },
      success: callback,
    });
  },
  patchJSON: function (url, data, callback) {
    return $.ajax({
      type: 'PATCH',
      url: url,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
      processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-HTTP-Method-Override', 'PATCH');
      },
      success: callback,
    });
  },
  postJSON: function (url, data, callback) {
    return $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json',
      processData: false,
      success: callback,
    });
  },
});
